import { DeleteForeverOutlined, ExpandMoreOutlined, LockOpenOutlined, LockPersonOutlined, PreviewOutlined, RefreshOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip, Typography,Tabs,Tab } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import 'dayjs/locale/it';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import * as React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import { cancelProcessEmail, releaseProcessMail, searchEmail, setOperatorMail, startProcessIA } from '../../client/apiClient';
import { exactTextAtom, fromDateAtom, searchTextAtom, selectedMailAtom, sse, toDateAtom } from '../../states/wizardState';
import MailInfoDialog from './mailInfoDialog';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
export default function DataRangePage() {
    const [fromDate, setFromDate] = useRecoilState(fromDateAtom);
    const [toDate, setToDate] = useRecoilState(toDateAtom);
    const [searchText, setSearchText] = useRecoilState(searchTextAtom);
    const [exactText, setExactText] = useRecoilState(exactTextAtom);

    const [processingMail, setProcessingMail] = React.useState([]);
    const [unprocessedMail, setUnprocessedMail] = React.useState([]);
    const [completedMail, setCompletedMail] = React.useState([]);
    const [discardedMail, setDiscardedMail] = React.useState([]);

    const [loadingUnprocessedTable, setLoadingUnprocessedTable] = React.useState();
    const [loadingProcessingTable, setLoadingProcessingTable] = React.useState();

    const [mailId, setMailId] = React.useState();
    const [openMailInfoDialog, setOpenMailInfoDialog] = React.useState(false);
    const [typeDetail , setTypeDetail]= React.useState("in_elab");

    const [openCancelProgressDialog, setOpenCancelProgressDialog] = React.useState(false);

    const [rowSelectionProcessing, setRowSelectionProcessing] = React.useState({});
    const [rowSelectionUnprocessed, setRowSelectionUnprocessed] = React.useState({});
   
    // eslint-disable-next-line
    const [selectedMail, setSelectedMail] = useRecoilState(selectedMailAtom);

    const sseClient = useRecoilValue(sse);

    // usato da sse
    const [updateSearch, setUpdateSearch] = React.useState();

    const [value, setValue] = React.useState(0);

    const columns = React.useMemo(
        () => [
            {
                accessorKey: "id_mail", header: "Lavorazione",
                Cell: ({ renderedCellValue, row }) => (
                    row.original.status_elaboration.in_elaboration === true && row.original.status_elaboration.perc_avz ?
                        <Tooltip title={row.original.status_elaboration.msg}>
                            <><Box className="elaborazioneLoader"></Box>
                                <Box className="elaborazioneMsg"> {row.original.status_elaboration.type_elaboration + " " + row.original.status_elaboration.perc_avz}</Box></>
                        </Tooltip> : "-")
            },
            {
                accessorKey: "sent", header: "Data", size: 90,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        component="span"
                        sx={{ whiteSpace: "pre" }}
                    >
                        {new Date(renderedCellValue).toLocaleString().split(',').join('\n')}
                    </Box>
                ),
            },
            {
                accessorKey: "from_address", header: "Da", grow: true,

                Cell: ({ renderedCellValue, row }) => (
                    <Tooltip title={renderedCellValue}>
                        <Box
                            component="span"
                        >
                            {renderedCellValue}
                        </Box>
                    </Tooltip>
                ),

            },
            {
                accessorKey: "subj", header: "Oggetto", grow: true,

                Cell: ({ renderedCellValue, row }) => (
                    <Tooltip title={renderedCellValue}>
                        <Box
                            component="span"
                        >
                            {renderedCellValue}
                        </Box>
                    </Tooltip>
                ),

            },
            {
                accessorKey: "id", header: "Info", size: 130,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        component="span"
                        sx={{ whiteSpace: "pre" }}
                    >
                        {row.original.contains_waste &&
                            "Rifiuti: ✓\n"
                        }
                        {row.original.contains_detail &&
                            "Distinta: ✓\n"
                        }
                        {row.original.is_cim &&
                            "Cim: ✓\n"
                        }
                        {row.original.contains_single_wagon &&
                            "Carro Singolo: ✓\n"
                        }
                        {row.original.already_started &&
                            "Lavorazione: ✓"
                        }
                    </Box>
                )
            },
            {
                accessorKey: "has_warning", header: "Warnings / Errors", size: 170,
                Cell: ({ renderedCellValue, row }) => (
                    <Box sx={{ whiteSpace: "pre" }}>
                        <Box sx={{ color: "orange", fontWeight: "bold" }}>
                            {renderedCellValue ? <span>Warnings</span> : <></>}
                        </Box>

                        <Tooltip title={row.original.status_elaboration.msg}>
                            <Box sx={{ cursor: "pointer", color: "red", fontWeight: "bold" }}>
                                {row.original.status_elaboration.in_error ?
                                    <span>Errori</span> : <></>}
                            </Box>
                        </Tooltip>

                    </Box>
                ),
            },
            {
                accessorKey: "num_attachs", header: "Allegati", size: 100,
                Cell: ({ renderedCellValue, row }) => (
                    <Box
                        sx={{
                            width: "50px",
                            textAlign: 'right'
                        }}
                    >
                        <span>{renderedCellValue}</span>
                    </Box>
                ),
            }],
        []);

    const unprocessedTable = useMaterialReactTable({
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        initialState: {
            density: "compact",
            columnPinning: {
                right: ['mrt-row-actions'],
            },
        },
        muiTableContainerProps: { sx: { maxHeight: '30vh' } },
        localization: MRT_Localization_IT,
        data: unprocessedMail,
        columns,
        enablePagination: false,
        enableRowVirtualization: true,
        enableExpandAll: true,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableColumnResizing: true,
        getRowId: (row) => row.id,
        positionToolbarAlertBanner: 'bottom',
        onRowSelectionChange: setRowSelectionUnprocessed,
        state: { rowSelection: rowSelectionUnprocessed, showLoadingOverlay: loadingUnprocessedTable },
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActionMenuItems: ({ row, table, closeMenu }) => {
            if (row.original.status_elaboration && row.original.status_elaboration.mail_analizer && row.original.status_elaboration.mail_analizer.in_error === false) {
                return <div><Button className='button-style' variant='text' startIcon={<PreviewOutlined />} table={table} onClick={() => {closeMenu(); emailDetail(row.id)}}>Visualizza</Button>
                    <br />
                    <Button className='button-style' variant='text' startIcon={<LockPersonOutlined />} table={table} onClick={() => {closeMenu(); acquire(row.original.id_mail)}}>Acquisisci</Button>
                    <br />
                    <Button className='button-style' variant='text' startIcon={<DeleteForeverOutlined color='error' />} sx={{ color: "#d32f2f" }} table={table} onClick={() => { closeMenu(); setMailId(row.id); setOpenCancelProgressDialog(true) }}>Scarta</Button>
                </div>
            }
            else {
                return <div><Button variant='text' startIcon={<DeleteForeverOutlined />} sx={{ color: "#d32f2f" }} table={table} onClick={() => { closeMenu(); setMailId(row.id); setOpenCancelProgressDialog(true) }}>Scarta</Button></div>
            }
        }
    });

    const processingTable = useMaterialReactTable({
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        initialState: {
            density: "compact",
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        muiTableContainerProps: { sx: { maxHeight: '30vh' } },
        localization: MRT_Localization_IT,
        data: processingMail,
        columns,
        enableExpandAll: true,
        enableColumnResizing: true,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        getRowId: (row) => row.id,
        positionToolbarAlertBanner: 'bottom',
        onRowSelectionChange: setRowSelectionProcessing,
        enablePagination: false,
        enableRowVirtualization: true,
        state: { rowSelection: rowSelectionProcessing, showLoadingOverlay: loadingProcessingTable },
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActionMenuItems: ({ row, table }) => {
            return (
                <div>
                    <Button className='button-style' variant='text' startIcon={<PreviewOutlined />} table={table} onClick={() => emailDetail(row.id)}>Visualizza</Button>
                    <br />
                    <Button className='button-style' variant='text' startIcon={<LockOpenOutlined />} sx={{ color: "green" }} table={table} onClick={() => { releaseProcess(row.original.id_mail); }}>Rilascia</Button>
                    <br />
                    <Button className='button-style' variant='text' startIcon={<DeleteForeverOutlined />} sx={{ color: "red" }} table={table} onClick={() => { setMailId(row.id); setOpenCancelProgressDialog(true) }}>Scarta</Button>
                    <br />
                    <Button className='button-style' variant='text' startIcon={<RefreshOutlined />} sx={{ color: "blue" }} table={table} onClick={() => { restartElaboration(row.original.id_mail) }}>Rielabora</Button>
                </div>
            )
        }
    });

    const completedTable = useMaterialReactTable({
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        initialState: {
            density: "compact",
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        muiTableContainerProps: { sx: { maxHeight: '30vh' } },
        localization: MRT_Localization_IT,
        data: completedMail,
        columns,
        enableExpandAll: true,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableMultiRowSelection: false,
        getRowId: (row) => row.id,
        positionToolbarAlertBanner: 'bottom',
        enablePagination: false,
        enableRowVirtualization: true,
        state: { rowSelection: rowSelectionProcessing, showLoadingOverlay: loadingProcessingTable },
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActionMenuItems: ({ row, table }) => {
            return (
                <div>
                    <Button className='button-style' variant='text' startIcon={<PreviewOutlined />} table={table} onClick={() => emailDetail(row.id)}>Visualizza</Button>
                    <br />
                </div>
            )
        }
    });

    const discardedTable = useMaterialReactTable({
        muiTableBodyCellProps: { sx: { borderRight: '1px solid #eee' } },
        initialState: {
            density: "compact",
            columnPinning: {
                right: ['mrt-row-actions'],
            }
        },
        muiTableContainerProps: { sx: { maxHeight: '30vh' } },
        localization: MRT_Localization_IT,
        data: discardedMail,
        columns,
        enableExpandAll: true,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableMultiRowSelection: false,
        getRowId: (row) => row.id,
        positionToolbarAlertBanner: 'bottom',
        enablePagination: false,
        enableRowVirtualization: true,
        state: { rowSelection: rowSelectionProcessing, showLoadingOverlay: loadingProcessingTable },
        enableRowActions: true,
        positionActionsColumn: "last",
        renderRowActionMenuItems: ({ row, table }) => {
            return (
                <div>
                    <Button className='button-style' variant='text' startIcon={<PreviewOutlined />} table={table} onClick={() => emailDetail(row.id)}>Visualizza</Button>
                    <br />
                </div>
            )
        }
    });

    React.useEffect(() => {
        if (Object.keys(rowSelectionProcessing).length > 0) {
            setRowSelectionUnprocessed({});
            let val = Object.keys(rowSelectionProcessing)[0];
            let sm = { ...processingMail.find(item => item.id === val) };
            sm.available = false;

            setSelectedMail(sm);
        }
        else {
            if (Object.keys(rowSelectionUnprocessed).length === 0) {
                setSelectedMail();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelectionProcessing]);

    React.useEffect(() => {
        if (Object.keys(rowSelectionUnprocessed).length > 0) {
            setRowSelectionProcessing({});
            let val = Object.keys(rowSelectionUnprocessed)[0];
            let sm = { ...unprocessedMail.find(item => item.id === val) };
            sm.available = true;

            setSelectedMail(sm);
        }
        else {
            if (Object.keys(rowSelectionProcessing).length === 0) {
                setSelectedMail();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowSelectionUnprocessed]);

    React.useEffect(() => {
        if (updateSearch) {
            let data = JSON.parse(updateSearch);

            if (data.id) {

                //refresh fine aggiornamento
                if (data.message.perc_avz >= 100) {
                    search();
                    return;
                }

                let processing = [...processingMail];
                let unprocessed = [...unprocessedMail];

                let index = processing.findIndex(item => item.id === data.id);

                if (index !== -1) {
                    processing[index].status_elaboration = { ...processingMail[index].status_elaboration }

                    processing[index].in_acquisition = data.in_acquisition;
                    processing[index].status_elaboration.in_elaboration = data.message.in_elaboration;
                    processing[index].status_elaboration.msg = data.message.msg;
                    processing[index].status_elaboration.in_error = data.message.in_error;
                    processing[index].status_elaboration.type_elaboration = data.message.type_elaboration;
                    if (data.message.perc_avz < 0) {
                        processing[index].status_elaboration.perc_avz = null;
                    }
                    else
                        processing[index].status_elaboration.perc_avz = data.message.perc_avz + "%";

                    setProcessingMail(processing);
                }
                else {

                    let index = unprocessed.findIndex(item => item.id === data.id);
                    if (index !== -1) {
                        unprocessed[index].status_elaboration = { ...unprocessedMail[index].status_elaboration }

                        unprocessed[index].in_acquisition = data.in_acquisition;
                        unprocessed[index].status_elaboration.in_elaboration = data.message.in_elaboration;
                        unprocessed[index].status_elaboration.msg = data.message.msg;
                        unprocessed[index].status_elaboration.in_error = data.message.in_error;
                        unprocessed[index].status_elaboration.type_elaboration = data.message.type_elaboration;
                        if (data.message.perc_avz < 0) {
                            unprocessed[index].status_elaboration.perc_avz = null;
                        }
                        else
                            unprocessed[index].status_elaboration.perc_avz = data.message.perc_avz + "%";

                        setUnprocessedMail(unprocessed);
                    }
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateSearch]);

    React.useEffect(() => {
        search();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {

        if (sseClient) {
            console.log("avvio sselistener");

            sseClient.addEventListener('new_mails', search);

            sseClient.addEventListener('upd_mails', updateSearchEvent);
            sseClient.addEventListener('run_cim_extractor', updateSearchEvent);
            sseClient.addEventListener('run_detail_extractor', updateSearchEvent);
            sseClient.addEventListener('run_codes_extractor', updateSearchEvent);
            sseClient.addEventListener('run_orfeus_extractor', updateSearchEvent);
            sseClient.addEventListener('run_rds_extractor', updateSearchEvent);

            return () => {
                console.log("rimozione sselistener");

                sseClient.removeEventListener("new_mails", search);

                sseClient.removeEventListener("upd_mails", updateSearchEvent);
                sseClient.removeEventListener("run_cim_extractor", updateSearchEvent);
                sseClient.removeEventListener("run_detail_extractor", updateSearchEvent);
                sseClient.removeEventListener("run_codes_extractor", updateSearchEvent);
                sseClient.removeEventListener("run_orfeus_extractor", updateSearchEvent);
                sseClient.removeEventListener("run_rds_extractor", updateSearchEvent);
            };
        }
    }, [sseClient]);

    const updateSearchEvent = (event) => {
        setUpdateSearch(event.data);
    }

    const search = () => {
        setLoadingProcessingTable(true);
        setLoadingUnprocessedTable(true);

        searchEmail(fromDate, toDate, searchText, exactText).then(res => {
            if (res) {
                setProcessingMail(res.data.processing);
                setUnprocessedMail(res.data.unprocessed);
                setCompletedMail(res.data.completed);
                setDiscardedMail(res.data.discarded);
            }

            setLoadingProcessingTable(false);
            setLoadingUnprocessedTable(false);

        }).catch(() => { /*ANNULLA*/ })
    }

    const reset = () => {
        setFromDate(dayjs());
        setToDate(dayjs());
        setSearchText("");
        setExactText(false);
        searchEmail(dayjs(), dayjs(), "", false).then(res => {
            if (res) {
                setProcessingMail(res.data.processing);
                setUnprocessedMail(res.data.unprocessed);
                setCompletedMail(res.data.completed);
                setDiscardedMail(res.data.discarded);
            }
        }).catch(() => { /*ANNULLA*/ });
    }

    const emailDetail = (id) => {
        setMailId(id);
        setOpenMailInfoDialog(true);
    }

    const acquire = (id) => {
        setOperatorMail(id).then(res => { search(); }).catch(() => { /*ANNULLA*/ });
    }

    const cancelProcess = (reason) => {
        cancelProcessEmail(mailId, reason).then(res => { search(); }).catch(() => { /*ANNULLA*/ });
    }

    const releaseProcess = (mailId) => {
        releaseProcessMail(mailId).then(res => { search(); }).catch(() => { /*ANNULLA*/ });
    }

    const restartElaboration = (mailId) => {
        startProcessIA(mailId).then(res => { search(); }).catch(() => { /*ANNULLA*/ });
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue==0)
            setTypeDetail("in_elab");
        else if(newValue==1)
            setTypeDetail("completed");
        if(newValue==2)
            setTypeDetail("canceled");
      };
      
    return (
        <Container maxWidth="xxl">
            {openMailInfoDialog &&
                <MailInfoDialog
                    open={openMailInfoDialog}
                    mailId={mailId}
                    type={typeDetail}
                    onClose={() => { setOpenMailInfoDialog(false); setMailId();  }}
                />
            }

            <React.Fragment>
                <Dialog
                    open={openCancelProgressDialog}
                    onClose={() => setOpenCancelProgressDialog(false)}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            cancelProcess(formJson.reason);
                            setOpenCancelProgressDialog(false);
                        },
                    }}
                >
                    <DialogTitle>Scartare Lavorazione?</DialogTitle>
                    <DialogContent sx={{ width: "350px" }}>
                        <DialogContentText>
                            Indicare una motivazione
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="reason"
                            name="reason"
                            label="Motivazione"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="inherit" variant='outlined' onClick={() => setOpenCancelProgressDialog(false)}>Annulla</Button>
                        <Button color="error" variant='contained' type="submit">Procedi</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

            <LocalizationProvider adapterLocale='it' dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                        label="Data inizio"
                        value={fromDate}
                        onChange={(val) => setFromDate(val)}
                    />
                    <DatePicker
                        label="Data fine"
                        value={toDate}
                        onChange={(val) => setToDate(val)}
                    />

                    <TextField id="outlined-basic" value={searchText} onChange={(e) => setSearchText(e.target.value)} label="Ricerca testuale" variant="outlined" />

                    <Button variant="contained" onClick={search}>Cerca</Button>
                    <Button variant="outlined" color='error' onClick={reset}>Reset</Button>

                </DemoContainer>
            </LocalizationProvider>

            <Accordion defaultExpanded={true} className='accordionMail'>
                <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel-content"
                    id="panel-header"
                >
                    <Typography style={{ fontWeight: "bold" }}>Disponibili ({unprocessedMail.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <MaterialReactTable
                        key={"unprocessed"}
                        table={unprocessedTable} />
                </AccordionDetails>
            </Accordion>

            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
               <Tab label={"In lavorazione("+processingMail.length.toString()+")"}  {...a11yProps(0)} />
               <Tab label={"Completati("+completedMail.length.toString()+")"} {...a11yProps(1)} />
               <Tab label={"Scartati("+discardedMail.length.toString()+")"} {...a11yProps(2)} />
           </Tabs>

           <CustomTabPanel  value={value} index={0}>
               <MaterialReactTable key={"processing"} table={processingTable} />
           </CustomTabPanel>
           <CustomTabPanel  value={value} index={1}>
               <MaterialReactTable key={"completed"} table={completedTable} />
           </CustomTabPanel >
           <CustomTabPanel  value={value} index={2}>
               <MaterialReactTable key={"discarded"} table={discardedTable} />
               </CustomTabPanel >



        </Container>
    );
}